.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}
.loader > ul {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: rotate(45deg) translate(-50%, -50%);
}
.loader > ul > li {
  list-style-type: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  background: #99ccff;
  border-radius: 50%;
}
#a {
  animation: a 1s ease-in-out infinite;
  top: -40px;
  left: -40px;
}
#b {
  animation: b 1s ease-in-out infinite;
  top: -40px;
  left: 0px;
}
#c {
  animation: c 1s ease-in-out infinite;
  top: -40px;
  left: 40px;
}
#d {
  animation: d 1s ease-in-out infinite;
  top: 0px;
  left: -40px;
}
#e {
  animation: e 1s ease-in-out infinite;
  top: 0px;
  left: 0px;
}
#f {
  animation: f 1s ease-in-out infinite;
  top: 0px;
  left: 40px;
}
#g {
  animation: g 1s ease-in-out infinite;
  top: 40px;
  left: -40px;
}
#h {
  animation: h 1s ease-in-out infinite;
  top: 40px;
  left: 0px;
}
#i {
  animation: i 1s ease-in-out infinite;
  top: 40px;
  left: 40px;
}

@keyframes a {
  50% {
    top: 0px;
    left: -40px;
  }
  100% {
    top: 0px;
    left: -40px;
  }
}
@keyframes b {
  50% {
    top: -40px;
    left: -40px;
  }
  100% {
    top: -40px;
    left: -40px;
  }
}
@keyframes c {
  50% {
    top: -40px;
    left: 0px;
  }
  100% {
    top: -40px;
    left: 0px;
  }
}
@keyframes d {
  50% {
    top: 40px;
    left: -40px;
  }
  100% {
    top: 40px;
    left: -40px;
  }
}
@keyframes f {
  50% {
    top: -40px;
    left: 40px;
  }
  100% {
    top: -40px;
    left: 40px;
  }
}
@keyframes g {
  50% {
    top: 40px;
    left: 0px;
  }
  100% {
    top: 40px;
    left: 0px;
  }
}
@keyframes h {
  50% {
    top: 40px;
    left: 40px;
  }
  100% {
    top: 40px;
    left: 40px;
  }
}
@keyframes i {
  50% {
    top: 0px;
    left: 40px;
  }
  100% {
    top: 0px;
    left: 40px;
  }
}
